/*! Themestr.app `Fresca` Bootstrap 4.3.1 theme */
@import url(https://fonts.googleapis.com/css?family=Roboto:200,300,400,700);
/*!
 * Bootstrap v4.1.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #EFF0E2;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #EB8157;
  --secondary: #82BEC0;
  --success: #11D3BC;
  --info: #A2D5F2;
  --warning: #FF7E67;
  --danger: #F67280;
  --light: #FAFAFA;
  --dark: #4e4e4e;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }


$primary: #EB8157;
$secondary: #82BEC0;

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: Roboto;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

body {
  margin: 0;
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }


.bg-primary {
  background-color: #31364B !important; }

.btn-primary {
  color: #fff;
  background-color: $primary;
  border-color: $primary;

  &:hover {
    background-color: darken($primary, 7%);
    border-color: darken($primary, 7%);
  }
}

.btn-secondary {
  color: #fff;
  background-color: $secondary;
  border-color: $secondary;

  &:hover {
    background-color: darken($secondary, 7%);
    border-color: darken($secondary, 7%);
  }
}

nav {
  a {
    color: white;

    &:hover {
      color: darken(white, 7%);
    }
  }
}

a {
  color: darken($secondary, 20%);

  &:hover {
    color: darken($secondary, 27%);
  }
}
